/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap'); */


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.section {
  height: 100svh; /* Adjusted to 100% of the viewport height */
  width: 100%;
}

.m-title-page {
  height: 100svh; /* Adjusted to 100% of the viewport height */
  width: 100%; /* Full viewport width */
  overflow: hidden;
}

.m-title-page-container {
  height: 100svh; /* Adjusted to 100% of the viewport height */
  width: 100%; /* Full viewport width */
  position: relative;
  background-color: #F1F6FF;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}

.navbar {
  display: flex;
  align-items: center;
  background-color: #F1F6FF;
  color: #256EFB;
  height: 7.43svh;
  width: 100%;
  max-height: 74.3px;
  box-shadow: 0px 1px 30px 0px rgba(183,162,232, 0.175);
  z-index: 5;
}

.mtitle-secundary-container {
  position: absolute;   
  left: 9%;
  height: 4.2svh;
  width: 25vw;
  max-height: 42px;
}

.mtitle-secundary {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.mflagcontainer{
  width: 6vw;
  height: 2.5svh;
  max-height: 25px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.mflag-icon{
  width: 5vw;
  margin-right: 2px;
}

.marrow-icon {
  font-size: min(2svh,20px);      /* Adjust size of the arrow icon */
  color: #021E3E;
}

.marrowcontainer{
  width: 2.5vw;
  height: 2.5svh;
  max-height: 25px;
  display: flex;
  align-items: center;
}

.mlangcontainer{
  width: 6.5vw;
  height: 2.5svh;
  max-height: 25px;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 2px;
}

.mlangtext {
  color: #021E3E;
  text-decoration: none; 
  font-family: 'Noto Sans', sans-serif; /* Apply the Noto Sans font */
  font-weight: 600;     /* Allows positioning of the border */
  /* font-size: 2.2vh; */
  font-size: min(2.3svh, 4.2vw);
}

.mlanguage-dropdown-cont {
  position: absolute;
  text-align: center;
  z-index: 2;
  top: 0vh;
  height: 7.43svh;
  max-height: 74.3px;
  left: 70vw;
  width: 16vw;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.mlanguage-selector {
  text-align: center;
  display: flex;
  align-items: center;
  z-index: 4;
  display: flex;
  flex-direction: row;
  width: 16vw;
}

.mdropdown-menu{
  position: absolute;
  text-align: center;
  top: 75%;
  padding-top: 0.5svh;
  padding-bottom: 0.5svh;
  width: 10vw;
  background-color: #F1F6FF;
  border-radius: 8px 8px 8px 8px;
  box-shadow: -3px 3px 10px 0px rgba(183,162,232, 0.3);
  z-index: 4;
}

.mdropdown-item{
  padding-top:0.5svh;
  padding-bottom: 0.5svh;
  z-index: 4;
}

.mtitle-lang{
  color: #021E3E;
  text-decoration: none; 
  font-family: 'Noto Sans', sans-serif; /* Apply the Noto Sans font */
  font-weight: 600;     /* Allows positioning of the border */
  font-size: 1rem;
  z-index: 2;
}

.hamburger-menu {
  cursor: pointer;
  display: flex;
  position: absolute;
  left: 86.75%;
  height: 7.43svh;
  max-height: 74.3px;
  align-items: center;
}

i {
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

.nav-links {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: min(7.43vh,74.3px);
  right: 0px;
  padding-left:20px;
  padding-right: 20px;
  padding-top:6px;
  padding-bottom:8px;
  border-radius: 0px 0px 8px 8px;
  display: none;
  background-color: #F1F6FF;
  box-shadow: -3px 3px 10px 0px rgba(183,162,232, 0.3);
  z-index:5;
}

.nav-links.open {
  display: flex;
  z-index: 5;
  
}

.nav-links li {
  margin: 10px 0;
}

.nav-links a {
  color: #021E3E;
  text-decoration: none; 
  font-family: 'Noto Sans', sans-serif; /* Apply the Noto Sans font */
  font-weight: 600;     /* Allows positioning of the border */
  font-size: 1rem;
  z-index:5;
}

.nav-links a:hover {
  color: #256EFB;
}

.mtitle-curl1-container {
  position: absolute;
  top: min(7.43svh, 74.3px);   /* 10% from the top */
  left: 65%;
  width: 35%;
  height: 20svh;
  z-index: 2;
}

.mtitle-curl1 {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.mtitle-jointly-cont {
  /* position: absolute; */
  margin-top: min(8svh, 80px);   
  margin-left: 8.5%;
  height: 7svh;
  max-height: 70px;
  width: 60%;
  text-align: left;
  z-index: 5;
}

.mtitle-jointly {
  color: #256EFB;
  text-decoration: none; 
  font-family: 'Raleway', sans-serif; /* Apply the Noto Sans font */
  font-weight: 700;     /* Allows positioning of the border */
  font-size: max(min(6svh,60px), 13vw);
  z-index: 2;
}

.mtitle-slogan-cont {
  /* position: absolute; */
  margin-top: 18px;
  margin-left: 9%;
  width: 70%;
  text-align: left;
  z-index: 5;
}

.mtitle-slogan {
  color: #021E3E;
  text-decoration: none; 
  font-family: 'Raleway', sans-serif; /* Apply the Noto Sans font */
  font-weight: 500;     /* Allows positioning of the border */
  font-size: 4.5vw;
  z-index: 2;
}

.mtitle-intro-cont {
  /* position: absolute; */
  margin-top: min(1.8svh,18px);
  margin-left: 8.5%;
  height: 12svh;
  width: 92.5%;
  text-align: left;
  z-index: 2;
}

.mtitle-intro {
  color: #021E3E;
  text-decoration: none; 
  font-family: 'Raleway', sans-serif; /* Apply the Noto Sans font */
  font-weight: 600;     /* Allows positioning of the border */
  /* font-size: 3.2vh; */
  font-size: min(8.5vw, 4.2svh);
  display: block;
}

.mtitle-introNL {
  color: #021E3E;
  text-decoration: none; 
  font-family: 'Raleway', sans-serif; /* Apply the Noto Sans font */
  font-weight: 600;     /* Allows positioning of the border */
  /* font-size: 3.2vh; */
  font-size: min(8.5vw, 4.2svh);
  display: block;
}

.mtitle-cards-container {
  margin-top: min(4svh, 45px);
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.mtitle-cards {
  /* width: 85%;
  height: 30vh;
  object-fit: fill;
  z-index: 2; */
  width: auto;
  /* height: 30vh; Ensures the height is scaled based on the width */
  max-height: min(42svh, 420px);
  max-width: 90%;
  object-fit: fill; /* Ensures the image scales correctly without distortion */
  z-index: 5;
}

.mtitle-button-cont {
  margin-top: min(3.5svh, 35px);
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 5;
}

.mtitle-curl2-container {
  position: absolute;
  top: 80%;   /* 10% from the top */
  left: 0;
  /* height: 20vh; */
  height: min(20svh, 200px);
  z-index: 2;
}

.mtitle-curl2 {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.mblurred-circle {
  position: absolute;
  /* width: 43.23vw;
  /* height: 43.23vw; */
  /* top: 13.86vh; */
  /* left: 46.67vw; */
  top:-20%;
  width: 50svh;
  height: 40svh;
  max-height: 400px;
  background: radial-gradient(circle at 50% 50%, rgba(37, 110, 251, 0.3) 0%, rgba(37, 110, 251, 0.18) 60%, rgba(37, 110, 251, 0.05) 100%);
  filter: blur(150px);
  z-index: 1;
}


.scroll-indicator {
  position: absolute;
  bottom: 18px;
  font-size: 30px; /* Adjust icon size */
  display: flex;
  justify-content: center;
  width: 100%;
  color: #333; /* Icon color */
  z-index: 3; /* Make sure it's above other elements */
  cursor: pointer;
  height:20px;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.bounce {
  animation: bounce 2s infinite;
}

.scroll-ind-icon{
  height: 23px;
  width: 23px;
  color: #256EFB;
}


.m-howto-page {
  height: 100vh; /* Adjusted to 100% of the viewport height */
  width: 100%; /* Full viewport width */
  overflow: hidden;
}

.m-howto-page-container {
  height: 100vh; /* Adjusted to 100% of the viewport height */
  width: 100%; /* Full viewport width */
  position: relative;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}

.mhowto-title-cont {
  /* position: absolute; */
  margin-top: 7vh;
  display: flex;
  justify-content: center;
  text-align: center;
  z-index: 2;
}

.mhowto-title {
  color: #021E3E;
  text-decoration: none; 
  font-family: 'Noto Sans', sans-serif; /* Apply the Noto Sans font */
  font-weight: 700;    /* Allows positioning of the border */
  font-size: 3.5vh;
  z-index: 2;
}

.mhowto-desc-cont {
  margin-top: 3vh;
  margin-left: 12.5%;
  display: flex;
  justify-content: center;
  width: 75%;
  text-align: center;
  z-index: 2;
}

.mhowto-desc {
  color: #021E3E;
  text-decoration: none; 
  font-family: 'Noto sans', sans-serif; /* Apply the Noto Sans font */
  font-weight: 500;     /* Allows positioning of the border */
  font-size: min(2vh, 3.8vw);
  z-index: 2;
  display: block;
}

.highlight {
  color: #256EFB;
}

.mhowto-cards-cont{
  display: flex; /* Align cards horizontally */
  transition: transform 0.5s ease; /* Smooth transition for swipe effect */
  width: 400%; /* Assuming 4 cards, adjust accordingly */
  margin-top: 9vh;   
  height: 35vh;
  z-index: 3;
}


.mhowto-card-cont {
  margin-left: 5%;
  margin-right: 5%;
  width: 15%;
  border-radius: 15px;
  background-color:white;
  height: 100%; /* Full height of the container */
  box-shadow:
                -5px 0px 20px rgba(37, 110, 251, 0.05), /* Left shadow */
                5px 0px 20px rgba(37, 110, 251, 0.05),  /* Right shadow */
                0px 5px 20px rgba(37, 110, 251, 0.05);  
}


.mhowto-card1t-cont {
  position: relative;
  height: 48%;
  width: 100%;
  z-index: 3;
  border-radius: 15px 15px 0px 0px;
  background-color: #FFA8A8;
  display: flex;
  justify-content: center;
}

.mhowto-card2t-cont {
  position: relative;
  height: 48%;
  width: 100%;
  z-index: 3;
  border-radius: 15px 15px 0px 0px;
  background-color: #FFD873;
  display: flex;
  justify-content: center;
}

.mhowto-card3t-cont {
  position: relative;
  height: 48%;
  width: 100%;
  z-index: 3;
  border-radius: 15px 15px 0px 0px;
  background-color: #7E88FC;
  display: flex;
  justify-content: center;
}
.mhowto-card4t-cont {
  position: relative;
  height: 48%;
  width: 100%;
  z-index: 3;
  border-radius: 15px 15px 0px 0px;
  background-color: #E6FFF1;
  display: flex;
  justify-content: center;
}

.mhowto-card1b-cont {
  position: relative;
  height: 52%;
  width: 100%;
  z-index: 3;
  border-radius: 0px 0px 15px 15px;
  background-color:white;
}

.mhowto-cardimg {
  position: absolute;
  height: 134.4%;
  width: 85%;
  top:-34.4%;
  left: 7.5%;
  z-index: 4;
}

.mhowto-cardtext-cont {
  position: absolute;
  top: 12.63%;   
  left: 6.81%;
  height: 62.6%;
  width: 85%;
  z-index: 4;
  text-align: left;
}

.mhowto-cardtext {
  color: #021E3E;
  text-decoration: none; 
  font-family: 'Raleway', sans-serif; /* Apply the Noto Sans font */
  font-weight: 600;     /* Allows positioning of the border */
  font-size: 5vw;
  z-index: 4;
}


.dots-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: rgba(37, 110, 251, 0.22);
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: rgba(37, 110, 251, 1); /* Active dot color */
}

.mhowto-button-cont {
  margin-top: 7.5vh;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 5;
}

.mhowto-curl1-container {
  position: absolute;
  top: 51.5vh;   /* 10% from the top */
  left: 0vh;
  height: 22vw;
  z-index: 2;
}

.mhowto-curl1 {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.mhowto-curl2-container {
  position: absolute;
  top: 30vh;   /* 10% from the top */
  left: 57vw;
  width: 43vw;
  z-index: 2;
}

.mhowto-curl2 {
  height: 100%;
  width: 100%;
  object-fit: fill;
}


.m-socials-page {
  height: 100vh; /* Adjusted to 100% of the viewport height */
  width: 100%; /* Full viewport width */
  overflow: hidden;
}

.m-socials-page-container {
  height: 100vh; /* Adjusted to 100% of the viewport height */
  width: 100%; /* Full viewport width */
  position: relative;
  background-color: #256EFB;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}

.msocials-title-cont {
  /* position: absolute; */
  margin-top: 11vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  z-index: 2;
}

.msocials-title {
  color: #FFFFFF;
  text-decoration: none; 
  font-family: 'Raleway', sans-serif; /* Apply the Noto Sans font */
  font-weight: 700;    /* Allows positioning of the border */
  font-size: min(4.2vh, 6.8vw);
  z-index: 2;
}

.msocials-title-nl {
  color: #FFFFFF;
  text-decoration: none; 
  font-family: 'Raleway', sans-serif; /* Apply the Noto Sans font */
  font-weight: 700;    /* Allows positioning of the border */
  font-size: min(4.2vh, 6.8vw);
  z-index: 2;
}


.msocials-seeyou-cont {
  margin-top: 7.5vh;
}

.msocials-seeyou-text {
  color: white;
  text-decoration: none; 
  font-family: 'Raleway', sans-serif; /* Apply the Noto Sans font */
  font-weight: 500;     /* Allows positioning of the border */
  font-size:  min(3.2vh, 6.5vw);
  z-index: 2;
  display: block;
}

.msocials-imgs-cont{
  display: flex; /* Align cards horizontally */
  margin-top: 11vh;
  width: calc(7*3.75vw + 6*1.075*21vh);
  z-index: 3;
  height: 21vh;
  padding-left: 3vw;
  padding-right: 5vw;
  overflow: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS devices */
}

.msocials-img-cont {
  margin-left: 3.75vw;
  height: 21vh;
  border-radius: 12px;
  overflow: hidden;
}

.msocials-img {
  height: 100%;
  width: 100%;
  object-fit: fill;
  z-index: 2;
}

.socials-dots-container {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  z-index: 2;
}

.socialdot {
  width: 8px;
  height: 8px;
  background-color: rgba(256,256,256, 0.22);
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 2;
}

.socialdot.active {
  background-color: #FFFFFF; /* Active dot color */
}

.msocials-button-container {
  margin-top: 7.5vh;
  display: flex;
  justify-content: center;
}

.m-footer-page {
height: 68vh; /* Adjusted to 100% of the viewport height */
width: 100%; /* Full viewport width */
overflow: hidden;
}

.m-footer-page-container {
height: 68vh; /* Adjusted to 100% of the viewport height */
width: 100%; /* Full viewport width */
position: relative;
background-color: #021E3E;
display: flex;
flex-direction: column;
/* overflow: hidden; */
}

.mfooter-secundary-container {
  margin-top: 5vh;
  height: 5.7vh;
  width: 100%;
  display: flex;
  justify-content: left;
}

.mfooter-secundary-link {
  height: 100%;
  object-fit: fill;
}

.mfooter-secundary {
  height: 100%;
  object-fit: fill;
  margin-left: 6vw;
}

.mfooter-desc-container {
  margin-top: 2vh;
  width: 90%;
  display: flex;
  justify-content: left;
  text-align: left;
}

.mfooter-desc {
  width: 100%;
  margin-left: 6vw;
  color: white;
  font-family: 'Noto Sans', sans-serif; /* Apply the Noto Sans font */
  font-weight: 400;     /* Allows positioning of the border */
  font-size: 4vw;
}

.footer-icon1-container {
  position: absolute;
  top: 10.4vh;   
  left: 63vw;
  height: 9.75vh;
  width: 9.75vh;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mfooter-icons-container {
  margin-top: 3.5vh;
  height: 5vh;
  width: 45%;
  z-index: 2;
  margin-left: 6vw;
  display: flex;
  justify-content: space-between;
}

.mfooter-icon {
  height: 4.7vh;
  width: 4.7vh;
  object-fit: fill;
  z-index: 3;
}

.mfooter-icon2 {
  height: 4.7vh;
  width: 4.7vh;
  object-fit: fill;
  z-index: 3;
  background-color: white;
}

.line {
  margin-top: 3.5vh;
  margin-left: 6vw;
  width: 88vw;          /* Set the width to 80% of the container */
  height: 1px;         /* Set the height to 1px */
  background-color: rgba(256,256,256,0.4);  /* Set the color of the line */
}

.mfooter-links-outercont{
  margin-top: 3vh;
  width: 100%;
  height: 10vh;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mfooter-links-innercont{
  margin-left: 6vw;
  width: 94%;
  height: 3vh;
  z-index: 2;
  display: flex;
  justify-content: left;
}

.mfooter-link-left{
  width:44vw;
  text-align: left;
}

.mfooter-link{
  color: white;
  font-family: 'Noto Sans', sans-serif; /* Apply the Noto Sans font */
  font-weight: 600;     /* Allows positioning of the border */
  font-size: max(4.4vw, 2.2vh);
}

.line2 {
  margin-top: 3.5vh;
  margin-left: 6vw;
  width: 88vw;          /* Set the width to 80% of the container */
  height: 1px;         /* Set the height to 1px */
  background-color: rgba(256,256,256,0.4);  /* Set the color of the line */
}

.mfooter-copy {
  color: white;
  text-decoration: none; 
  font-family: 'Noto sans', sans-serif; /* Apply the Noto Sans font */
  font-weight: 400;     /* Allows positioning of the border */
  font-size: 0.9rem;
  z-index: 2;
}

.mfooter-copyright-container {
  margin-top: 4vh;
  width: 90%;
  display: flex;
  justify-content: left;
  text-align: left;
  margin-left: 6vw;
}

.mfooter-copyright-left {
  width: 100%;
  color: white;
  font-family: 'Noto Sans', sans-serif; /* Apply the Noto Sans font */
  font-weight: 400;     /* Allows positioning of the border */
  font-size: 3.3vw;
  text-align: left;
}

.mfooter-copyright-right {
  width: 100%;
  color: white;
  font-family: 'Noto Sans', sans-serif; /* Apply the Noto Sans font */
  font-weight: 400;     /* Allows positioning of the border */
  font-size: 3.3vw;
  text-align: right;
}

.mfooter-terms-container {
  margin-top: 0.8vh;
  width: 88vw;
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-left: 6vw;
}